// new css
.LoginSec .logoImage {
  width: 210px;
  margin: 60px 0;
}

.LoginSec h2.logIn-Heading {
  font-size: 24px;
  font-weight: 300;
  font-family: system-ui;
  color: #000;
  margin-bottom: 26px;
}
.LoginSec .LogInBtn {
  width: 100%;
  background-color: #000000 !important;
  color: #ffffff !important;
  border-color: #f4f4f4;
  font-weight: 600;
}
.LoginSec .LogInBtn:hover {
  background-color: #292929 !important;
  color: white !important;
  border-color: #f4f4f4 !important;
}
.LoginSec .logBtnDiv {
  margin-top: 40px;
}
.LoginSec .inputDiv {
  padding-right: 50px;
}
.LoginSec input {
  height: 44px;
  color: #000 !important;
  border-color: #8a8a8a;
}
.LoginSec .LogInBtn:active,
:focus-visible {
  background-color: #f4f4f4 !important;
  color: #8a8a8a !important;
  border-color: #f4f4f4 !important;
}
.LoginSec input[placeholder] {
  font-weight: 400;
  color: #8a8a8a;
  font-size: 14px;
}
.LoginSec input:focus {
  box-shadow: none !important;
  border-color: #000 !important;
}
.LoginSec input:hover {
  border-color: #000000 !important;
}

/* Extra small devices (phones, 600px and down) */
@media (max-width: 767px) {
  .mobileView {
    display: none;
  }
  .LoginSec {
    margin-top: 10px !important;
    padding-left: 30px !important;
  }

  .LoginSec .logoImage {
    margin: 40px 0;
  }
  .LoginSec h2.logIn-Heading {
    font-size: 24px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 767px) and (max-width: 992px) {
  .LoginSec {
    width: 360px !important;
  }
  img.bgMainImg {
    width: 100%;
  }
  // .bgMain {
  //     padding-top: 0px;
  // }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  img.bgMainImg {
    width: 100%;
  }
  .LoginSec .inputDiv {
    padding-right: 0;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media (min-width: 1200px) {
}

// .side-dashboard{
//     display: none;
// }
// .wrapper{
//     display: block;
// }
// .mobileheader{
//     display: none;
// }
.LogInBtn-active{
  background-color: #f4f4f4 !important;
  color: #050505 !important;
}


// New hupe input css

/* hupe input field */
.inputField {
  position: relative;
}

.inputField input {
  padding: 5px 15px;
  border: 1.8px solid rgba(107, 107, 107, 0.4);
  border-radius: 6px;
  width: 100%;
  color: black;
}

.inputField input:focus {
  border: 2.3px solid #6b6b6b;
  outline: none;
  background-color: #fff !important;
}

// .inputField span {
//   pointer-events: none;
//   opacity: 1;
//   position: absolute;
//   padding-left: 20px;
//   // left: 0;
//   top: 50%;
//   font-size: 12px;
//   font-weight: 700;
//   -webkit-transform: translateY(-50%);
//   transform: translateY(-50%);
//   cursor: text;
//   color: rgb(73, 73, 73);
// }

// .inputField input:focus + span,
// .inputField input:not(:placeholder-shown) + span:target {
//   top: 2px;
//   left: -3px;
//   -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
//   transform: scale(0.7) translateY(-10%) translateX(-8.5px);
//   color: rgb(88, 88, 88);
//   font-weight: 700;
// }

// .inputField input,
// .inputField span {
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   -webkit-transition: all 0.2s;
//   transition: all 0.2s;
//   -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
//   transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
// }
.showHide {
  right: 10px;
  z-index: 100;
  position: absolute;
  cursor: pointer;
  top: 14px;
  font-size: 11px;
  font-weight: 700;
}