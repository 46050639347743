.spinner-loading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #0e0f0f;
    width: 120px;
    height: 120px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
