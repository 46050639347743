.btn-close {
  opacity: 1;
  filter: contrast(0.1);
}

.modal-header {
  border-bottom: 0px;
}
.resultTable {
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 550 !important;
}
.modal-body {
  padding-bottom: 25px;
}
.btn-close:focus {
  box-shadow: none !important;
}
.FixedHeadingPop{
  position: sticky;
  z-index: 2;
  left: -2px;
  background-color: #202328 !important;
}
