@media (min-width: 1500px) {
  .inputslider .slider {
    width: 64%;
  }

  .inputslider {
    top: 87px;
  }
}
@media (min-width: 1701px) and (max-width: 1900px) {
  .inputslider {
    top: 40%;
  }
}

@media (min-width: 1461px) and (max-width: 1700px) {
  .inputslider {
    top: 60px;
  }
}

@media (min-width: 1035px) and (max-width: 1100px) {
  .inputslider {
    top: 60px;
  }
  .bt-table tr th:first-child {
    // background-color: #070c12;
  }
}
@media (min-width: 1100px) and (max-width: 1199px) {
  .inputslider {
    top: 60px;
  }
  .bt-table tr th:first-child {
    // background-color: #070c12;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
  }
}
@media (min-width: 1220px) and (max-width: 1300px) {
  .AdminAdjust {
    height: auto;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1299px) {
  h6.percentageNumber {
    font-size: 30px;
  }
  .ProgressBigSec .imgDiv.progressBar {
    padding: 0 !important;
  }
  // h5.progressbar-component-heading.bigHeading {
  //   font-size: 18px;
  // }
  .edit-icons {
    position: absolute;
    cursor: pointer;
    right: 5px;
    top: 5px;
    color: white;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1600px) {
  .secf {
    flex: 0 0 100px;
  }
  .ProgressBigSec {
    width: 45%;
    margin: 5px;
  }
  .edit-icons {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    color: white;
  }
}
@media only screen and (min-width: 767px) and (max-width: 991px) {
  /*---------adminmobile-----------*/
  .bottomheaderSec .menu-side .item:first-child {
    flex: 0 0 473px;
  }
  .adminwraper .admin-panal {
    min-height: calc(100vh - 104px);
  }
  .inputslider {
    top: 84px;
  }
  /*---------adminmobile-----------*/
}

@media (max-width: 1500px) {
  .low {
    position: relative;
    left: -8px;
    top: -4px;
  }

  .high {
    position: relative;
    right: -10px;
    top: -4px;
  }
}

@media (max-width: 1299px) {
  .secf {
    display: none;
  }
}

@media (max-width: 1124px) {
  .kpitable th:not(:first-child) {
    min-width: 150px;
  }
  .progressCol {
    padding: 0;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
  }
  .search-bar input {
    font-size: 13px;
  }
}
@media (min-width: 992px) and (max-width: 1034px) {
  .inputslider {
    top: 58px;
  }
  .bt-table tr th:first-child {
    // background-color: #070c12;
  }
}
@media (min-width: 767px) and (max-width: 991px) {
  .inputslider {
    top: 55px;
  }
  .search-bar input {
    font-size: 16px;
  }
  .clients-list a {
    font-size: 22px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  /*---------adminmobile-----------*/
  .bottomheaderSec .menu-side .item:first-child {
    flex: 0 0 473px;
  }
  #profile .bt-table tr th:first-child {
    // background-color: #070c12;
  }
  h6.percentageNumber {
    font-size: 40px;
    font-weight: 400;
  }
  .topnav ul ul li a {
    padding: 6px;
  }
  h6.isBigProgressBarHeading {
    font-size: 55px;
  }
  .sechover {
    margin: 5px;
    padding: 3px;
  }
  .ProgressBigSec {
    padding: 20px;
  }
  .bt-table tr th:first-child {
    background-color: #202328;
  }
  .heading3 {
    display: none;
  }
  /*---------adminmobile-----------*/
  .edit-icons {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    color: white;
  }
}

@media (max-width: 1500px) {
  .low {
    position: relative;
    left: -8px;
    top: -4px;
  }

  .high {
    position: relative;
    right: -10px;
    top: -4px;
  }
}

@media (max-width: 1124px) {
  .kpitable th:not(:first-child) {
    min-width: 150px;
  }
}
@media (max-width: 991px) {
  .progressSec ul {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  body.modal-open {
    padding-right: 0 !important;
  }

  a.nav-link.dropdown.collapsed.clientsHeading {
    font-size: 22px;
  }
  .kpi-graph-shared {
    display: none;
  }
  .spaceN1 {
    padding-left: 0px;
    padding-right: 0px;
  }

  // .imgDiv div {
  //   width: 100% !important;
  // }

  .t-r th:not(:first-child) {
    width: 21%;
  }
  .space,
  .spaceN2 {
    padding-left: 0 !important;
    padding-right: 10px !important;
  }
  .destopview {
    display: none;
  }
  .mobileview {
    display: block;
  }
  .sidebar-anchor {
    width: 100%;
    border-radius: 0px !important;
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 54px;
    border-bottom: 1px solid #dde1e3;
  }
  .listitem:not(:last-child) {
    margin-bottom: 0px;
  }

  .mobileheader .navbar {
    padding: 10px 0px;
  }

  .mobileheader .container-fluid {
    padding-left: 0px;
    padding-right: 0px;
  }
  .mobileheader .navbar-brand {
    padding-left: 15px;
  }
  .mobileheader {
    display: block;
    width: 100%;
  }

  .side-dashboard {
    display: none;
  }
  .wrapper {
    flex-direction: column;
  }
  .MainPannel {
    width: 100%;
    height: auto;
  }

  .cordsySec {
    height: auto;
    padding: 20px 10px;
  }
  #nav-icon1 {
    width: 35px;
    height: 19px;
    float: right;
    margin-top: 0px;
    right: 3px;
    position: relative;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  #nav-icon1 span {
    display: block;
    height: 3px;
    width: 26px;
    background: #202328;
    border-radius: 9px;
    opacity: 1;
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  #nav-icon1 span:nth-child(1) {
    top: 0;
  }
  #nav-icon1 span:nth-child(2) {
    top: 11px;
  }
  #nav-icon1.open span:nth-child(2) {
    top: 11px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  #nav-icon1.open span:nth-child(2) {
    top: 5px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  #nav-icon1.open span:nth-child(1) {
    top: 5px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  #nav-icon1 span,
  .icon {
    position: absolute;
    right: 25px;
  }
  .icon img {
    padding: 0 3px;
  }

  .mobileheader ul {
    padding-left: 0px;
    margin-bottom: 60px;
    margin-top: 15px;
  }

  .sidebar-anchor.active,
  .sidebar-anchor:hover {
    background: rgb(221 225 227 / 51%);
  }
  .componetsbox .col-2 {
    flex: 0 0 auto;
    width: 25.666667%;
  }
  .mbb {
    margin-bottom: 3.4rem;
  }
  .blood-result {
    margin-top: 20px !important;
  }

  .clients-list a {
    text-align: center;
  }

  /*---------adminmobile-----------*/
  .adminMobile {
    width: 100%;
    padding: 0px;
    display: block;
  }

  .adminMobile .navbar {
    width: 100%;
    height: 60px;
    padding: 0;
    border-bottom: 1px solid #dde1e3;
  }
  .adminDestop {
    display: none;
  }

  .adminmo-list {
    display: block !important;
    width: 100%;
  }

  .mobileview-sec > * {
    flex: 1;
    position: relative;
  }

  .mobileview-sec {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .mobileview-sec .clients-list .nav {
    flex-direction: column;
    position: absolute;
    background-color: #fff;
    left: 50%;
    transform: translateX(-50px);
    text-align: left;
    align-items: flex-start;
  }

  .bottomheaderSec .menu-side .item {
    padding: 0px;
    border: none;
  }

  .bottomheaderSec ul {
    display: flex;
    padding-left: 0px;
    justify-content: space-between;
    margin-bottom: 0px;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .mobileview-sec .navbar-brand {
    padding-left: 10px;
  }
  .search-bar1 input {
    background-size: 5%;
  }

  .search-bar1 input::placeholder {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }

  .navbar .clients-list .dropdown::after {
    top: 4px;
    position: relative;
  }

  .search-bar1 input::placeholder {
    color: #202328;
    opacity: 1;
  }

  .bottomheaderSec .sub-list a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #676d7c;
  }

  .bottomheaderSec .sub-list a {
    padding-top: 0px;
    padding-bottom: 0px;
    font-weight: 700;
  }

  .ipadview {
    display: block;
  }
  .iphoneview {
    display: none;
  }
  .topnav .nav-link span {
    top: 1px;
    right: 4px;
  }
  .bottomheaderSec {
    padding: 10px 0px 10px 15px;
  }
  .serc {
    padding-top: 17px;
    padding-bottom: 0px;
  }
  .serc .row:not(:first-child) {
    margin-top: 10px;
  }
  .search-bar1 {
    padding-left: 10px;
  }
  .mobileview-sec .navbar-brand {
    padding-left: 0px;
    margin-right: 0;
  }

  .custompopup .btn-close {
    box-sizing: content-box;
    width: 20px;
    height: 20px;
    color: #676d7c;
    border-radius: 0.375rem;
    opacity: 1;
    filter: contrast(0.12);
    font-size: 18px;
  }

  .custompopup .modal-header {
    border-bottom: 0px;
  }

  .custompopup .modal-content {
    background-color: #202328 !important;
    border-radius: 20px;
    // padding: 0px 38px 50px;
  }

  .custompopup .modal-dialog {
    max-width: 535px;
  }

  .custompopup .postivefactor {
    border: 1px solid #676d7c;
    border-radius: 20px;
  }

  .mt-sx-5 {
    margin-top: 25px;
  }
  .kpitable th:not(:first-child) {
    min-width: 128px;
    vertical-align: baseline;
  }

  .t-r thead th:first-child,
  .t-r tbody th:first-child {
    padding-left: 10px;
  }

  .t-r .table {
    border-spacing: 8px 0px;
  }
  .kpitable td:not(:first-child)::before {
    right: -10px;
    width: 8.9px;
    height: 58px;
    bottom: -3px;
  }
  .kpitable tbody th::before {
    right: -8px;
    width: 8px;
  }

  /*---------adminmobile-----------*/
}
@media (max-width: 767px) {
  .textClr {
    font-size: 22px;
  }
  .Mview.show {
    margin-left: -30px;
  }
  h6.isBigProgressBarHeading {
    font-size: 34px;
    padding: 4px !important;
  }

  .t-r tbody th {
    font-size: 14px;
  }
  .component-heading {
    font-size: 14px;
    line-height: 20px;
  }
  .componetsbox .col-2 {
    flex: 0 0 auto;
    width: 54.666667%;
  }
  .heading3 {
    font-size: 20px;
    display: none;
  }
  .bloodResultSec {
    padding: 0 5px;
  }
  .factorSec .col-lg-6:first-child {
    margin-bottom: 20px;
  }
  .inputslider .slider {
    width: 67%;
  }
  .mbb {
    margin-bottom: 60px;
  }
  .para1 {
    display: none;
  }
  .blood-result {
    margin-top: 0px !important;
  }
  .kpitable tbody tr.isActive-heading th::before {
    height: 56px;
  }
  .kpitable tr.isActive-heading td:not(:first-child)::before {
    height: 56px;
  }
  .search-bar {
    margin-top: 10px;
  }
  // ul.dropdown-menu.notificationDropdown.show {
  //   right: -60px;
  //   width: auto;
  // }
  .t-r thead th {
    font-size: 18px;
  }
  .t-r td {
    font-size: 20px;
  }
  .clients-list a {
    font-size: 22px;
  }
  // h5.progressbar-component-heading.bigHeading {
  //   font-size: 18px;
  // }
  .spaceN1 {
    padding: 0px 10px;
  }
  /*---------adminmobile-----------*/

  .ipadview {
    display: none;
  }
  .iphoneview {
    display: block;
  }

  .iphoneview .menu-side .nav-link {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    color: #676d7c !important;
  }

  .iphoneview .clients-list .nav {
    flex-direction: column;
    position: absolute;
    right: 3px;
    background-color: #fff;
    text-align: left;
    align-items: flex-start;
    z-index: 4;
    width: max-content;
    border-radius: 10px;
    margin-top: 11px;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  }
  a.dashboardChlid {
    padding: 12px 16px;
  }

  .search-bar1 input {
    background-size: 10%;
  }

  /*---------adminmobile-----------*/

  .MainPannel {
    height: 100%;
  }

  .table-responsive {
    margin-bottom: 50px;
  }
  body .progressbar-component-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    margin: 10px 0px 5px;
    // line-height: 15px;
  }

  .sechover {
    margin: 5px;
    padding: 5px 3px;
  }
  .bottomheaderSec ul {
    align-items: center;
  }
  .serc .row:not(:first-child) {
    margin-top: 0;
  }
  .admininner-sec {
    padding-left: 0px;
  }
  .sectr {
    align-items: center;
    padding-top: 50px;
  }
  .menu-side {
    padding-right: 15px;
  }
  .edit-icons {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    color: white;
  }
}
@media only screen and (min-width: 400px) and (max-width: 767px) {
  .inputslider {
    top: 60px;
  }
  .low {
    left: 5px;
  }

  .high {
    right: 5px;
  }
  h6.percentageNumber {
    font-size: 22px;
  }
}
@media (max-width: 400px) {
  .high {
    right: unset;
  }
  .low {
    left: unset;
  }
  .componetsbox .col-2 {
    flex: 0 0 auto;
    width: 61.666667%;
  }
  .component-box {
    padding: 20px 10px;
    height: 160px;
  }
  .inputslider {
    top: 25px;
  }

  .progressSec ul {
    width: 100%;
    margin-bottom: 50px;
  }
  .progressSec ul .nav-item {
    flex: 1;
  }

  .progressSec .nav-tabs .nav-link {
    width: 100%;
  }
  .report-popup .modal-header,
  .rp-sec,
  .rp-sec2 {
    padding: 25px 5px;
  }
  .btn-greeen {
    width: 200px;
  }
  body .progressbar-component-heading {
    font-size: 14px;
    padding-bottom: 0;
  }
  .iphoneview li:first-child {
    flex: 0 0 56%;
  }
  h6.percentageNumber {
    font-size: 22px;
  }
  .numbertile {
    top: 0px;
  }
}

@media (max-width: 350px) {
  .inputslider {
    top: 25px;
  }
}
@media (min-width: 320px) and (max-width: 485px) {
  .innerpro h5 {
    width: 75%;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .ProgressBigSec .imgDiv.progressBar {
    padding: 0 !important;
  }
  .bt-table tr th:first-child {
    background-color: #202328;
  }
  #profile .bt-table tr th:first-child {
    // background-color: #070c12;
  }
  .bgclr {
    min-height: calc(100vh - 104px);
  }
  .space {
    padding: 0 30px;
  }
  img.mobileArrowDown {
    padding-left: 5px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1560px) {
  .inputslider {
    top: 56px;
  }
  .bt-table tr th:first-child {
    // background-color: #070c12;
  }
  // .sechover {
  //   padding: 40px 0px !important;
  //   margin: 5px !important;
  // }
}

@media only screen and (min-width: 689px) and (max-width: 767px) {
  .component-box::before {
    background-size: cover;
  }
  h6.percentageNumber {
    font-size: 22px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1120px) {
  .CheckupSec {
    width: 245px;
  }

  .default-date-blood-result {
    width: inherit;
  }
  .CheckupSec img.downIcon {
    right: 0 !important;
  }
}
ul.dropdown-menu.notificationDropdown.show {
  left: -200px;
  width: auto;
}
@media (min-width: 1300px) and (max-width: 1400px) {
  .imgDiv1 {
    padding: 12px 0px;
  }
}
@media (max-width: 450px) {
  .edit-icons {
    width: 20px;
  }
}
