.postivefactor{
    position: relative;

    .factor-edit-btn{
        position: absolute;
        top:10px;
        right: 10px;
        color: white;
        cursor: pointer;
    }
}