
h2.CSVHeading {
    margin: 30px 10px;
    color: #dde1e3;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 1px;
    vertical-align: sub;
    display: flex;
    align-items: center;
}
input.InputFile {
    margin: 20px 0px 20px 0px;
    color: #fff;
}
input.InputFile[type=file]::file-selector-button {
    border: 2px solid #ffffff;
    padding: .2em .4em;
    border-radius: .2em;
    background-color: #000000;
    transition: .3s;
    color: #fff;
  }
  
  input.InputFile[type=file]::file-selector-button:hover {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    color: #000;
  }
  //   custom edit file
.file-input__input {
  z-index: -1;
}
.file-input{
  margin: 20px 10px;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 10px 12px;
  background-color: #000000;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border: 2px solid #fff;
  transition: .3s;
}
.file-input__label:hover{
  background-color: #ffffff;
  border: 2px solid #ffffff;
  color: #000;
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

  @media (min-width: 320px) and (max-width: 768px){
    h2.CSVHeading{
        font-size: 18px;
        margin: 20px 10px;
    }
    input.InputFile{
        font-size: 15px;
        width: 200px;
        margin: 20px 0;
      }
      button.file-input__label {
        font-size: 12px;
        padding: 6px;
    }
    .file-input {
      margin: 20px 0;
  }
  }


  .form-radio-btn{
    display: flex;
    margin-bottom: 10px;
    align-items: center;  
    
    .file-type-text{
      color:white;
      font-weight: 400px;
      font-size: 1.2rem;
    }
  }

  .form-check-inline {
    display: inline-block;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}