.graphHeading {
  color: #dde1e3;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.6px;
  vertical-align: sub;
  display: flex;
  align-items: center;
}
.progressBar {
  display: flex;
  justify-content: center;
  .edit-icons {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 30px;
    height: 30px;
    display: block !important;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  // }
  // &:hover{
  //   .edit-icons{

  //   }
  // }
}

.progressbar-component-heading {
  color: #dde1e3;
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  margin: 9px;
  padding-bottom: 5px;
}
.ProgressBigSec .imgDiv.progressBar {
  padding: 15px;
}
h2.PopupHeading {
  font-size: 24px;
  font-weight: 700;
  padding: 15px 5px;
}
