.offcanvas-edit-kpi {
  .fixed-bottom-content {
    position: fixed;
    bottom: 10px;
    right: 15px;
  }
  .fixed-bottom-content-btn {
    margin: 5px;
  }
}
.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
