$small: 768px;
$medium: 991px;

.hide-sm {
  @media screen and (max-width: $medium) {
    display: none;
  }
}

.isActive-heading {
  color: white !important;
  background: #0d2c22;

  th {
    color: white !important;
  }
}

.yellowclr {
  color: #f2994a !important;
}
.optimal-range{
  font-size: 12px;
  color: #676d7c !important;
}
