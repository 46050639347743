.default-date-blood-result {
  color: #676d7c;
}

.CheckupSec {
  position: relative;

  .btn-primary {
    background-color: black;
    border: 1px solid #676d7c !important;
    width: 100%;
    text-align: inherit;
    border-radius: 10px;
  }
  .btn-check:focus + .btn,
  .btn:focus {
    background-color: black;
    border-color: black;
    outline: 0;
    color: white;
  }

  .dropdown-menu {
    background-color: black;
    width: 53%;
  }
  a.default-date-blood-result:hover {
    color: #676d7c !important;
  }

  .btn:hover {
    background-color: black;
    color: white;
    outline: none;
  }
  span.checkupHeading {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
  }
  a.dropdown-item:hover {
    background-color: #202328;
    border-radius: 10px;
  }
  .dropdown-menu.show {
    width: 100%;
    padding: 10px;
    border: 1px solid #676d7c;
  }
  button#dropdown-menu-align-right {
    box-shadow: none;
    padding-left: 20px;
    font-weight: 600;
  }
  a.dropdown-item {
    margin: 5px;
  }
  img.downIcon {
    position: absolute;
    z-index: 1;
    right: 20px;
    top: 15px;
  }
  .CheckupSec .btn:hover  {
    background-color: black;
    color: white;
    outline: none;
  }
}
.boxyellow {
  background-color: #f2994a !important;
}

.CheckupSec .btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  background-color: black;
}
p.ObserveHeading {
  color: #676d7c;
  font-weight: 400;
  padding: 16px 25px;
}
.heading4 {
  color: #dde1e3;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  letter-spacing: 1px;
  vertical-align: sub;
  display: flex;
  align-items: center;
}

.progressColor {
  background-color: #202328;
}
.progressColor .bt-table tr th:first-child {
  background-color: #202328 !important;
}
.frame-icon img {
  padding: 0px 6px;
}
.cursorPointer {
  cursor: pointer;
}
.frame-icon{
  cursor: pointer;
}
.optimal-range{
  font-size: 12px !important;
  color: #676d7c !important;
  font-weight: 600;
}

@media (min-width:320px)  and (max-width:720px){
  .progressSec .bt-table td{
    white-space:nowrap;
  }
  th{
    white-space: nowrap;
  }
}