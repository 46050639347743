$small: 768px;
$medium: 992px;

.kpi-graph-shared {
  @media screen and (max-width: $medium) {
    display: none;
  }
}

.kpi-pointer-check {
  cursor: pointer;
}

.isActive-kpi {
  background-color: #27ae60 !important;
}

.is-target,
.is-actual {
  margin-top: 10px;
  margin-left: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.8px;
  color: #676d7c;
}
h4.KPI-PopupHeading {
  color: white;
  font-size: 20px;
  margin-bottom: 25px;
  margin-top: -6px;
}
.KpiTop {
  border-left: 1px solid #202328;
  height: calc(100vh - 65px);
}
.divimg {
  padding-top: 20px;
}
.add-row-table {
  cursor: pointer;
}
.edit-btn-kpi,
.delete-btn-kpi {
  margin: 0px 5px;
}
.isActiveIcon {
  color: white;
}

.kpi-pointer-check {
  .item-kpi-metric-name {
    font-size: 16px !important;
  }
  .td-kpi-item {
    font-size: 22px;
    span {
      font-size: 12px;
    }
  }
}

.phase-heading {
  display: flex;
  justify-content: flex-start;
}

.kpitable{
  max-height:600px}
  /* width */
    .kpitable::-webkit-scrollbar {
    width: 4px;
  }
   
  /* Handle */
  .kpitable::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  
.edit-icons-kpi{
  cursor: pointer;
  width: 8px !important;
  font-size: 18px;
  margin: 2px;
}

.kpi-matric-name{
  vertical-align: middle !important;
}
