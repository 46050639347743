.reportsec {
  .form-check-input:checked {
    background-color: #27ae60;
    border-color: #27ae60;
    box-shadow: none !important;
  }
  .form-check-input {
    box-shadow: none !important;
  }
}
